<template>
  <div class="mm-content-wrapper">
    <template
      v-for="(content, index) in tabContentList"
      :key="content?.props?.value"
    >
      <KeepAlive
        v-if="isKeepAlive(content?.props)"
        :key="content?.props?.value"
      >
        <component
          :is="content"
          v-if="content?.props?.value === value"
        />
      </KeepAlive>
      <template v-else>
        <component
          :is="content"
          v-if="content?.props?.value === value"
          :key="index"
        />
      </template>
    </template>  
  </div>
</template>

<script lang="ts" setup>
import { TTabValue } from '../../models/tabs.model';
import { useSlots, computed, VNode } from 'vue';
import Content from './Content.vue';
import { getCorrectVNodeList } from '../../utils/tabsHelper.util';

defineProps<{
  value: TTabValue;
}>();
const slots = useSlots();

const tabContentList = computed<VNode[]>(() => (
  getCorrectVNodeList(slots?.default?.() || [], Content)
));

function isKeepAlive(props: Record<string, unknown> | null): boolean {
  return !!props && (
    (('isKeepAlive' in props) && props['isKeepAlive'] !== false) ||
    (('is-keep-alive' in props) && props['is-keep-alive'] !== false)
  );
}

</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

.mm-content-wrapper {
  width: 100%;
}
</style>