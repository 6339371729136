import { VNode } from 'vue';
import AppTabContent from '../components/content/AppTabContent.vue';
import AppTab from '../components/tabs/Tab.vue';
import { TTabValue } from '../models/tabs.model';

export function getCorrectVNodeList(vnodeList: Array<VNode>, targetComponent: typeof AppTabContent | typeof AppTab): Array<VNode> {
    const valueTemp: Array<TTabValue> = [];
    let internalValueTemp = 0;
    return vnodeList?.reduce?.((acc: Array<VNode>, vnode) => {
        const { __name, __path } = vnode?.type as typeof AppTabContent;
        if (__name === targetComponent.__name && __path === targetComponent.__path) {            
            if (valueTemp.includes(vnode.props?.value)) {
                throw Error('getCorrectVNodeList - AppTabs: значения value должны быть уникальными');
            }
            if (!vnode.props || !('value' in vnode.props)) {
                vnode.props = { ...vnode.props, value: internalValueTemp++ };
            }
            valueTemp.push(vnode.props?.value);
            return [...acc, vnode];
        }
        return [...acc, ...getCorrectVNodeList(vnode.children as Array<VNode>, targetComponent)];
    }, []) || [];
}