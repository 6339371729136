<template>
  <div
    class="mm-tabs"
    :class="additionalClasses"
  >
    <div class="mm-tabs__tabs-wrapper">
      <component
        :is="tab"
        v-for="(tab, index) in tabList"
        :key="tab?.props?.key ?? index"
        :is-active="isMounted && isTabActive(tab?.props?.value)"
        :is-passed="isMounted && isTabPassed(tab?.props?.value)"
        @click="onSelectTab(tab?.props?.value)"
      />
    </div>
    <ContentWrapper :value="internalValue">
      <template
        v-for="(slot, index) in defaultSlots"
        :key="index"
      >
        <component :is="slot" />
      </template>
    </ContentWrapper>
  </div>
</template>

<script lang="ts" setup>
import { TTabValue } from '../../models/tabs.model';
import { computed, VNode, useSlots, ref, watch } from 'vue';
import Tab from './Tab.vue';
import { getCorrectVNodeList } from '../../utils/tabsHelper.util';
import ContentWrapper from '../content/ContentWrapper.vue';
import { ETabsPosition } from '../../enums/tabs/tabs-position.enum';
import { WatchSubscription } from '../../utils/watchSubscription';
import useSSRUnsubscribeWatch from '../../composables/useSSRUnsubscribeWatch';

const props = withDefaults(
  defineProps<{
    // value вкладок
    modelValue?: TTabValue;
    // Позицианирование вкладок
    position?: ETabsPosition;
    // Время для автопереключения в мс
    autoPlayTimeout?: number;
  }>(), {
    position: ETabsPosition.Top,
  },
);

const slots = useSlots();

const defaultSlots = (slots?.default?.() || []);

const timeout = ref<ReturnType<typeof setTimeout> | null>(null);
const isMounted = ref(false);

const emits = defineEmits<{
  (e: 'update:modelValue', value: TTabValue);
}>();

const internalValue = ref<TTabValue>();

const tabList = computed<Array<VNode>>(() => (
  getCorrectVNodeList(slots?.default?.() || [], Tab)
));

const watchSubscription = new WatchSubscription();
const additionalClasses = computed<Array<string>>(() => ([`mm-tabs--${props.position}`]));

function onSelectTab(value: TTabValue): void {
  setInternalValue(value);
  emits('update:modelValue', value);
  if (props.autoPlayTimeout) {
    startTimer();
  }
}

function isTabActive(value: TTabValue): boolean {
  return internalValue.value === value;
}

function isTabPassed(value: TTabValue): boolean {
  return internalValue.value > value;
}

function setInternalValue(value: TTabValue): void {
  internalValue.value = value;
}

function getNextTab(): VNode {
  return tabList.value[tabList.value.findIndex((tab) => tab.props?.value === internalValue.value) + 1] || tabList.value[0];
}

function clearTimer(): void {
  if (!timeout.value) {
    return;
  }

  clearTimeout(timeout.value);
  timeout.value = null;
}

function startTimer(): void {
  clearTimer();
  timeout.value = setTimeout(() => {
    onSelectTab(getNextTab()?.props?.value || 0);
  }, props.autoPlayTimeout);
}

watchSubscription.add(
  watch(
    () => props.modelValue,
    (value: TTabValue) => setInternalValue(value || tabList.value[0]?.props?.value || 0),
    { immediate: true },
  ),
);

useSSRUnsubscribeWatch(watchSubscription);

onMounted(() => {
  isMounted.value = true;
  if (props.autoPlayTimeout) {
    startTimer();
  }
});

onUnmounted(() => {
  clearTimer();
});
</script>

<style scoped lang="scss">
@import 'styles/base/common/variables';

$-content-indent: 10px;

.mm-tabs {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__tabs-wrapper {
    position: relative;
    overflow: auto;
    display: flex;

    &::before {
      content: '';
      position: absolute;
    }
  }

  .mm-content-wrapper {
    flex: 1;
  }

  &--top,
  &--bottom {
    .mm-tabs__tabs-wrapper {
      max-width: 100%;

      &::before {
        left: 0;
        right: 0;
      }

      :deep(.mm-tab:not(:first-child)) {
        margin-left: 40px;
      }
    }
  }

  &--top {
    .mm-tabs__tabs-wrapper {
      &::before {
        bottom: 0;
        border-bottom: solid 1px $gray-200;
      }

      :deep(.mm-tab) {
        border-bottom: solid 2px transparent;
      }

      :deep(.mm-tab--active) {
        border-bottom-color: $link;
      }
    }
  }

  &--bottom {
    flex-direction: column-reverse;

    .mm-tabs__tabs-wrapper {
      &::before {
        border-top: solid 1px $gray-200;
      }

      :deep(.mm-tab) {
        border-top: solid 2px transparent;
      }

      :deep(.mm-tab--active) {
        border-top-color: $link;
      }
    }

    .mm-content-wrapper {
      margin-bottom: $-content-indent;
    }
  }

  &--left,
  &--right {
    flex-direction: row;

    .mm-tabs__tabs-wrapper {
      flex-direction: column;

      &::before {
        top: 0;
        bottom: 0;
      }
    }
  }

  &--left {
    .mm-tabs__tabs-wrapper {
      &::before {
        border-right: solid 1px $gray-200;
        right: 0;
      }

      :deep(.mm-tab) {
        border-right: solid 2px transparent;
        padding-right: $-content-indent;
      }

      :deep(.mm-tab--active) {
        border-right-color: $link;
      }
    }

    .mm-content-wrapper {
      margin-left: $-content-indent;
    }
  }

  &--right {
    flex-direction: row-reverse;

    .mm-tabs__tabs-wrapper {
      &::before {
        border-left: solid 1px $gray-200;
        left: 0;
      }

      :deep(.mm-tab) {
        border-left: solid 2px transparent;
        padding-left: $-content-indent;
      }

      :deep(.mm-tab--active) {
        border-left-color: $link;
      }
    }

    .mm-content-wrapper {
      margin-right: $-content-indent;
    }
  }
}
</style>
